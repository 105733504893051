<template>
  <div class="chips-input">
    <slot :model="model" :add="add" />
    <ChipList v-if="selected.length" :items="selected" removable @remove="remove" />
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: Array,
  options: Array
})
const emit = defineEmits(['update:modelValue'])

const items = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const model = ref(null)

function add (item) {
  if (item === undefined) return

  model.value = item
  nextTick(() => model.value = null)

  if (!items.value.includes(item)) items.value.push(item)
}

function remove (item) {
  if (items.value.includes(item)) items.value.splice(items.value.indexOf(item), 1)
}

const selected = computed(() => items.value.map(id => props.options.length && typeof id === 'number' ? props.options.find(o => o.id === id) : id))
</script>

<style scoped lang="scss">
.chips-input {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
