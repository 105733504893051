<template>
  <ProfileField name="Специалист" v-for="(vacancy, i) in model" :key="vacancy.id" v-bind="$attrs"
                :removable="model.length > 1" @remove="removeVacancy(i)" single-col class="inputs-grid">
    <AtomInput
      class="input-col-2"
      name="Название вакансии"
      v-model="vacancy.title"
      :error="errors[`vacancies.${i}.title`]"
    />
    <AtomInput
      class="input-col-2"
      type="textarea"
      name="Описание"
      desc="Расскажите детали о задаче этого специалиста, об условиях работы и т. д."
      v-model="vacancy.description"
      :error="errors[`vacancies.${i}.description`]"
    />
    <AtomInput
      name="Оплата"
      symbol="₽"
      v-model="vacancy.payment"
      :error="errors[`vacancies.${i}.payment`]"
    />
    <AtomInput
      type="select"
      name="Формат оплаты"
      :options="paymentTypes"
      :can-deselect="false"
      v-model="vacancy.payment_type"
      :error="errors[`vacancies.${i}.payment_type`]"
    />
    <FilterSkillLevels
      class="input-col-2"
      select
      :items="skillLevels"
      v-model="vacancy.skill_level_id"
    />
    <FilterBusyness
      class="input-col-2"
      select
      v-model="vacancy.busyness"
    />
    <ChipsInput
      class="input-col-2"
      v-model="vacancy.skills"
      :options="skills"
      v-slot="{ model, add }"
    >
      <SkillAtomInput
        name="Навыки"
        desc="Укажите навыки, которыми должен владеть специалист: напр. JavaScript"
        :model-value="model"
        @update:id="add"
        @update:text="add"
        :options="skills"
        :selected="vacancy.skills"
        :error="errors[`vacancies.${i}.skills`]"
      />
    </ChipsInput>
    <AtomInput
      v-if="requestSpecialists?.length"
      class="input-col-2"
      type="checkbox"
      :name="`Пригласить ${requestSpecialists?.length > 1 ? 'специалистов' : 'специалиста'} на эту вакансию`"
      :model-value="!!vacancy.request_specialists"
      @update:model-value="setRequestFor(vacancy, $event)"
      :error="errors[`vacancies.${i}.request_specialists`]"
    />
  </ProfileField>
  <ProfileFormAdd single-col @click="addVacancy" v-bind="$attrs">Добавить специалиста</ProfileFormAdd>
</template>

<script setup>
import { getSkillLevels, getSkills } from '~/api/skills'
import { paymentTypes } from '~/data/project'

const props = defineProps({
  modelValue: Array,
  errors: Object,
  requestSpecialists: Array
})
const emit = defineEmits(['update:modelValue'])

const { data: skillLevels } = await useLazyAsyncData(getSkillLevels, { default: () => [] })
const { data: skills } = await useLazyAsyncData(getSkills, { default: () => [], transform: d => d.data })

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

let counter = 1

watch(model, () => {
  model.value.forEach(e => { e.still_studying = e.still_studying ?? !e.year_to })
  if (model.value.length === 0) {
    addVacancy()
  } else {
    counter = model.value[model.value.length - 1].id + 1
  }
}, { immediate: true })

function addVacancy () {
  model.value.push({ id: counter++, payment_type: paymentTypes[0].id, skills: [] })
}
function removeVacancy (i) {
  model.value.splice(i, 1)
}

function setRequestFor (vacancy, value) {
  model.value.forEach(v => v.request_specialists = v.id === vacancy.id && value ? props.requestSpecialists : undefined)
}
</script>

<style scoped lang="scss">

</style>
