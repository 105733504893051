<template>
  <div class="default-grid row-gap">
    <div class="project-edit__sidebar">
      <UploadAvatar :input="true" :image="true" button="Загрузить обложку" :is-editable="true" v-model="form.cover_id" :error="errors.cover_id" :uploaded="data?.cover" :aspect-ratio="1.45" />
    </div>
    <div class="project-edit__main">
      <div v-if="!isMobile || isEditing || step === 0" class="project-edit__section">
        <ProfileFormHeader :editing="false">Основная информация</ProfileFormHeader>
        <div class="project-edit__section__body">
          <AtomInput
              class="project-edit__title"
              name="Название проекта"
              desc="Укажите название проекта, или что требуется сделать"
              v-model="form.title"
              :error="errors.title"
          />
          <AtomInput
              class="project-edit__period"
              name="Срок"
              :symbol="declOfNum(form.period ?? 0, ['день', 'дня', 'дней'])"
              :process-field="processField"
              v-model="form.period"
              :error="errors.period"
          />
          <AtomInput
              type="textarea"
              name="Описание проекта"
              desc="Расскажите о проекте и кратко опишите задачу"
              v-model="form.description"
              :error="errors.description"
          />
        </div>
      </div>
      <div v-if="!isMobile || isEditing ? isMainFilled : step === 1" class="project-edit__section project-edit__section--vacancies">
        <ProfileFormHeader
            :editing="false"
            subtitle="Добавьте информацию о специалистах, которые вам требуются"
            :error="errors.vacancies"
        >Специалисты</ProfileFormHeader>
        <div class="project-edit__section__body flex-mobile-separator">
          <ProjectVacancyInput
              v-model="form.vacancies"
              :errors="errors"
              :request-specialists="requestSpecialists"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: Object,
  errors: Object,
  data: Object,
  isEditing: Boolean,
  step: Number,
  requestSpecialists: Array
})
const emit = defineEmits(['update:modelValue', 'update:step'])

const { isMobile } = useBreakpoints()

const form = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
const step = computed({
  get: () => props.step,
  set: value => emit('update:step', value)
})
const isMainFilled = computed(() => form.value.title && form.value.description)

watch(() => props.errors, errors => Object.keys(errors).some(k => ['title', 'period', 'description'].includes(k)) && (step.value = 0))

const processField = value => value.toString().replace(/\D/g, '')
</script>

<style scoped lang="scss">
.project-edit {
  &__sidebar {
    grid-column: span 4;
    @media (max-width: 920px) {
      grid-column: span 12;
    }

    :deep(.avatar-upload-wrap) {
      height: 194px;
      @media (max-width: 920px) {
        width: 100%;
        height: auto;
        aspect-ratio: 1.45;
      }
    }
  }
  &__main {
    grid-column: span 8;
    @media (max-width: 920px) {
      grid-column: span 12;
    }
  }
  &__section {
    &:not(:first-child) {
      margin-top: 32px;
      border-top: 1px solid var(--color-separator);
      padding-top: 32px;
    }
    &__body {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 24px;
      > :deep(*) {
        grid-column: span 6;
      }
      :deep(.filter-control) {
        padding: 0;
        border-bottom: none;
      }
    }
    &--vacancies .project-edit__section__body {
      gap: 32px;
    }
  }
  &__title {
    grid-column: span 4;
    @media (max-width: 920px) {
      grid-column: span 6;
    }
  }
  &__period {
    grid-column: span 2;
    @media (max-width: 920px) {
      grid-column: span 6;
    }
  }
}
</style>
